import {Location} from "./location";
import {fromIODateNumber, IODateNumber, IOMinutesFromMidnight, toIOMinutesFromMidnight} from "./time";
import {IOScheduleList, process_task_schedule_for_day} from "./processSchedule";
import {LocationChat} from "./chat";
import * as Parse from "parse";

export type IOTaskStatus =  'open' | 'closed';
export type IOTaskState = { [field: string]: IOTaskStateEntry };

export interface IOTaskProcessMatch {
    day?: IODateNumber; // If linked to a particular day, most processes are.
    time?: IOMinutesFromMidnight; // If linked to a particular scheduled time.
}

export interface IOTask extends IOTaskProcessMatch {
    id: string;

    assignedUserId: string;
    assignedRoleId: string[];

    process: Process;

    day?: IODateNumber; // If linked to a particular day, most processes are.
    time?: IOMinutesFromMidnight; // If linked to a particular scheduled time.
    autoSchedule?: boolean; // True if scheduled from system

    progress: number;

    //
    // instance?: Parse.Pointer; // If multi-step process

    status: IOTaskStatus;

    closedTime?: Date;

    state: IOTaskState;

    chat?: LocationChat;
}

export type TaskAccess = true | 'done' | 'notassigned' | 'tooearly' | 'lateotherexist';

export class Task extends Parse.Object<IOTask & { location: Location }> implements IOTask {

    constructor(attr?: Partial<IOTask & { location: Location }>) {
        super('Task', attr as any);
    }

    get key(): string { return this.id }

    get assignedUserId(): string { return this.get("assignedUserId") }
    set assignedUserId(assignedUserId: string) { this.set("assignedUserId", assignedUserId) }

    get assignedRoleId(): string[] { return this.get("assignedRoleId") }
    set assignedRoleId(assignedRoleId: string[] ) { this.set("assignedRoleId", assignedRoleId) }

    get process(): Process { return this.get("process") }
    set process(process: Process) { this.set("process", process) }

    get day(): IODateNumber|undefined { return this.get("day") }
    set day(day: IODateNumber|undefined) { this.set("day", day) }

    get time(): IOMinutesFromMidnight|undefined { return this.get("time") }
    set time(time: IOMinutesFromMidnight|undefined) { this.set("time", time) }

    get progress(): number { return this.get("progress") }
    set progress(progress: number) { this.set("progress", progress) }

    get chat(): LocationChat | undefined { return this.get("chat") }
    set chat(chat: LocationChat | undefined) { this.set("chat", chat) }

    // This is set by server
    get closedTime(): Date|undefined { return this.get("closedTime") }

    public nextTaskInSchedule(location: Location): IOScheduleList | undefined {
        // Check if another task exists in schedule..
        const day = this.day;
        const time = this.time;

        const processSchedule = location.schedule[this.process.id];
        if (processSchedule && day && time) {
            const taskSchedule = process_task_schedule_for_day(fromIODateNumber(day), this.process.id,
                processSchedule, location);

            const indexOfMe = taskSchedule.findIndex(schedule => schedule.time === time);

            if (indexOfMe !== -1 && indexOfMe < taskSchedule.length-1) {
                return taskSchedule[indexOfMe+1];
            }
        }

        return undefined;
    }

    public canChangeTask(userId: string | undefined, location: Location): TaskAccess {

        if (this.closedTime || this.progress === 1) {
            return "done";
        }

        const now = toIOMinutesFromMidnight(new Date());
        const time = this.time;
        if (time) {
            if (Math.abs(time-now) <= 30) {
                // Within 30 minutes..
                if (userId && userId !== this.get("assignedUserId")) {
                    return "notassigned";
                }

                return true;
            }

            if (time > now) {
                // More than 30 min in the future..
                return "tooearly";
            } else {
                const next = this.nextTaskInSchedule(location);
                if (next) {
                    // If next within execution, dont allow this one to be executed.
                    if (next.time < now - 30) {
                        return "lateotherexist";
                    }
                }
            }
        }

        if (userId && userId !== this.get("assignedUserId")) {
            return "notassigned";
        }

        return true;
    }

    // get instance(): Parse.Pointer | undefined { return this.get("instance") }
    // set instance(instance: Parse.Pointer | undefined) { this.set("instance", instance) }

    get status(): IOTaskStatus { return this.get("status") }
    set status(status: IOTaskStatus) { this.set("status", status) }

    get state(): IOTaskState { return this.get("state") || {} }
    public setState(id: string, value: IOTaskStateEntry)     {
        let _state = this.get("state");
        if (!_state) _state = {};
        _state[id] = value;

        this.set("state", _state);
    }


}
// @ts-ignore
Parse.Object.registerSubclass('Task', Task);


export interface IOTaskStateEntry {
    value: any;
    time: Date;
    userId?: string;

    /** These fields are for security tracking if available */
    method?: 'web'|'email'|'sms'|'app';
    location?: { latitude: number;
        longitude: number} | string;
    uploadTime?: Date; // Set if task state done offline and uploaded later.
}

export interface IOInstance {
    id: string;

    tasks: IOTask[];
}

export interface IOProcessSteps {
    type: 'Checklist' | 'Form'
}

export interface IOCheckList extends IOProcessSteps {
    type: 'Checklist';
    id: string;
    text: string;
    photoURL?: string;

    items: IOCheckListItem[];

    archivedItems?: { [id: string] : IOCheckListItem }
}

export type IOCheckListType = 'check'|'scan'|'photo';

export interface IOCheckListItem {
    id: string;
    text: string;
    type: IOCheckListType;

    description?: string;
    photoURL?: string;
}

export type IOProcessId = string;

export type IOProcessType = 'checklist' | 'other';

export interface IOProcess {
    id: IOProcessId;
    name: string;

    /*
     * Checklist - Process with just a Checklist. there will be just one step.
     */
    type: IOProcessType;

    steps: IOProcessSteps[] | IOProcessSteps;
}

export const PROCESS = "Process"

export class Process extends Parse.Object<IOProcess & { location: Location }> {

    constructor(attr?: Partial<IOProcess>) {
        super(PROCESS, attr as any);
    }

    get key(): string { return this.id }

    get name(): string { return this.get("name") }
    set name(name: string) { this.set("name", name) }

    get type(): IOProcessType { return this.get("type") }
    set type(type: IOProcessType) { this.set("name", type) }

    get steps(): IOProcessSteps[] | IOProcessSteps { return this.get("steps") }
    set steps(steps: IOProcessSteps[] | IOProcessSteps) { this.set("steps", steps) }
}

Parse.Object.registerSubclass(PROCESS, Process);

export const checklistQRCode = (url: string): undefined | {processId: string, stepId: string} => {
    const m = url.match(/http:\/\/(.*)\/static\/task\?_p=(.*)&_s=(.*)/);
    if (!m) return undefined;
    return { processId: m[2], stepId: m[3] }
}

