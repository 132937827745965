import React, {ErrorInfo, Suspense} from 'react';
import './App.css';
import {QueryClient, QueryClientProvider} from "react-query";
import {useCurrentUserAccount} from "./app/state/account";
import {Loading} from "./components/Loading";
import * as Parse from "parse";

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error: Error, errorInfo:ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log("ErrorBoundary", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

const MainScreen = React.lazy(() => import("./pages/MainScreen"));
const PublicScreen = React.lazy(() => import("./pages/public/PublicScreen"));

export const queryClient = new QueryClient({
    defaultOptions: {
        queries:{
            refetchOnWindowFocus: false,
            refetchInterval: false
        },
        mutations: {
            retry: 3,
            retryDelay: 1000
        }
    }
});

function Screen() {
    const { data: user } = useCurrentUserAccount();

    if (user) {
        Parse.Analytics.track("app_open", { userId: user.id });
    }

    return <>{user ? <Suspense fallback={<Loading/>}><MainScreen/></Suspense> :
            <Suspense fallback={<Loading/>}><PublicScreen/></Suspense>}</>;
}

function App() {
    return (
    <QueryClientProvider client={queryClient}>
        <ErrorBoundary><Screen/></ErrorBoundary>
    </QueryClientProvider>);
}

export default App;
