import {describeIOMinutesFromMidnight, IODay, IOMinutes, IOMinutesFromMidnight} from "./time";
import {IOLocation} from "./location";
import {Task} from "./process";

export enum IOTaskScheduleTypes {
    OpenDays="open", // Task is done every day store is open
    Daily="daily", // Daily task (also on closed days)
    Weekly="weekly", // Weekly tasks, one or more days
    Montly="monthly", // Montly tasks
}

export enum IOTaskScheduleDailyTypes {
    BeginingOfDay="begin", // Done at the begining of the day
    EndOfDay="end", //
    Hourly="hourly",
    Times="times"
}

interface IOTaskScheduleDaily {
    type: IOTaskScheduleTypes.Daily | IOTaskScheduleTypes.OpenDays;
}
interface IOTaskScheduleDailySimple extends IOTaskScheduleDaily {
    times: IOTaskScheduleDailyTypes.BeginingOfDay | IOTaskScheduleDailyTypes.EndOfDay;
}
interface IOTaskScheduleDailyCustom extends IOTaskScheduleDaily {
    times: IOTaskScheduleDailyTypes.Times;

    customTimes: IOMinutesFromMidnight[];
}
interface IOTaskScheduleDailyHourly extends IOTaskScheduleDaily {
    times: IOTaskScheduleDailyTypes.Hourly;

    skip: IOMinutes;
}

interface IOTaskScheduleWeekly {
    type: IOTaskScheduleTypes.Weekly;
    days?: IODay[]; // Not set every day..
    time: IOTaskScheduleDailyTypes.BeginingOfDay | IOTaskScheduleDailyTypes.EndOfDay | IOMinutesFromMidnight;
}


export type IOTaskSchedule = IOTaskScheduleDailySimple | IOTaskScheduleDailyCustom | IOTaskScheduleDailyHourly | IOTaskScheduleWeekly;

export const describe_task_schedule = (schedule: IOTaskSchedule | undefined ) => {
    if (!schedule) {
        return "No automatic schedule. Only manually started";
    }

    switch (schedule.type) {
        case IOTaskScheduleTypes.OpenDays :
        case IOTaskScheduleTypes.Daily :
            switch (schedule.times) {
                case IOTaskScheduleDailyTypes.BeginingOfDay:
                    return schedule.type === IOTaskScheduleTypes.OpenDays ?
                        "Every open day at beginning of day" :
                        "Every day at beginning of day";
                case IOTaskScheduleDailyTypes.EndOfDay:
                    return schedule.type === IOTaskScheduleTypes.OpenDays ?
                        "Every open day at end of day" :
                        "Every day at end of day";
                case IOTaskScheduleDailyTypes.Hourly:
                    const hours = schedule.skip ? schedule.skip! / 60 : 60;
                    if (hours === 1) {
                        return schedule.type === IOTaskScheduleTypes.OpenDays ?
                            "Every hour every open day" :
                            "Every hour every day";
                    } else {
                        return schedule.type === IOTaskScheduleTypes.OpenDays ?
                            `Every ${hours} hour every open day` :
                            `Every ${hours} hour every day`;
                    }
                case IOTaskScheduleDailyTypes.Times:
                    return schedule.type === IOTaskScheduleTypes.OpenDays ?
                        "Every open day at " + describe_fixed_times(schedule) :
                        "Every day at at " + describe_fixed_times(schedule) ;
            }
            break;
        case IOTaskScheduleTypes.Weekly:
            return "Every week (TODO)";
        // case IOTaskScheduleTypes.Montly:
        //     return "Every month (TODO)";
    }

    return "Unknown schedule"
}

export const describe_fixed_times = (schedule: IOTaskScheduleDailyCustom) => {
    if (schedule.customTimes && schedule.customTimes.length > 0) {
        if (schedule.customTimes.length === 1) {
            return describeIOMinutesFromMidnight(schedule.customTimes[0]);
        }

        const times = schedule.customTimes.map(value => describeIOMinutesFromMidnight(value));
        const lastTime = times.splice(times.length - 2, 1);

        return times.join(", ") + " and " + lastTime;
    }

    return "none";
}

export interface IOScheduleList {
    definitionId: string;
    time: IOMinutesFromMidnight; // 0 - Midnight -> 2359 (past midnight is possible number is greater than 2400)

    // The original schedule time if different (time is then === linkedTask.startTime)
    // If there is a linkedTask and no scheduleTime, that means the task was manually started.
    scheduledTime?: IOMinutesFromMidnight;

    linkedTask?: Task;
}

const createScheduleItem = (definitionId: string, scheduledTime: IOMinutesFromMidnight, dailyTasks?: Task[]): IOScheduleList => {
    const ret: IOScheduleList = {
        time: scheduledTime,
        definitionId,
    };

    if (dailyTasks) {
        let index = dailyTasks.findIndex(value => {
            return value.time === scheduledTime && value.process.id === definitionId
        });

        if (index && index >= 0) {
            ret.linkedTask = dailyTasks[index];
        }
    }

    return ret;
}

export const process_task_schedule_for_day = (date: Date, id: string, processSchedule: IOTaskSchedule, location: IOLocation, dailyTasks?: Task[]) => {
    const ret: IOScheduleList[] = [];
    const hours = location.hours ? location.hours[date.getDay()] : undefined;

    switch (processSchedule.type) {
        case IOTaskScheduleTypes.Weekly:
            break;
        // @ts-ignore
        case IOTaskScheduleTypes.OpenDays:
            if (processSchedule.type === IOTaskScheduleTypes.OpenDays && !hours)
                break;
        // eslint-disable-next-line no-fallthrough  -- On purpose...
        case IOTaskScheduleTypes.Daily:
            switch (processSchedule.times || IOTaskScheduleDailyTypes.BeginingOfDay) {
                case IOTaskScheduleDailyTypes.BeginingOfDay:
                    if (hours)
                        ret.push(createScheduleItem(id, hours.start, dailyTasks));
                    break;
                case IOTaskScheduleDailyTypes.EndOfDay:
                    if (hours)
                        ret.push(createScheduleItem(id, hours.end, dailyTasks));
                    break;
                case IOTaskScheduleDailyTypes.Hourly:
                    if (hours) {
                        const skip = (processSchedule as IOTaskScheduleDailyHourly).skip || 60;
                        let hour = hours.start + skip;
                        while (hour < hours.end) {
                            ret.push(createScheduleItem(id, hour, dailyTasks));
                            hour += skip;
                        }
                    }
                    break;
                case IOTaskScheduleDailyTypes.Times:
                    if ((processSchedule as IOTaskScheduleDailyCustom).customTimes) {
                        const times = (processSchedule as IOTaskScheduleDailyCustom).customTimes.map(time => createScheduleItem(id, time, dailyTasks));
                        ret.push(...times);
                    }
                    break;
            }
            break;
    }

    return ret;
}

export const task_schedule_for_day = (date: Date, location?: IOLocation, tasks?: Task[]): IOScheduleList[] => {
    if (!location) {
        return [];
    }

    const ret: IOScheduleList[] = [];
    const dailyTasks = [...(tasks || [])];

    // const dailyTasks: IOTaskDataWithId[] = daily_tasks_list(dailyData);
    // dailyTasks.sort((a, b) => a.startTime - b.startTime);

    if (location.schedule) {
        for (const id of Object.keys(location.schedule)) {
            const processSchedule = location.schedule[id];

            ret.push(...process_task_schedule_for_day(date, id, processSchedule, location, dailyTasks));
        }
    }

    // Tasks that had no matching scheduled task
    // dailyTasks.forEach(task => {
    //     ret.push({
    //         definitionId: task.definitionId,
    //         time: timestamp_to_minutes(task.completeTime ? task.completeTime :
    //             task.startTime),
    //         linkedTask: task,
    //     })
    // });

    ret.sort((a, b) => a.time - b.time);
    return ret;
}

