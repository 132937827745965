import {useMutation, useQuery} from "react-query";
import {queryClient} from "../../App";
import {Location} from "../model/location";
import {_updateLocationTasks} from "./task";
import {_updateLocationUsers} from "./locationUser";
import {_updateLocationRoles} from "./locationRole";

let currentLocation: Location | undefined = undefined;
let currentLocationSubscription: () => void;

export const getCurrentLocation = (): Location | undefined => { return currentLocation };

export const setCurrentLocation = async (location: Location | undefined) => {
    console.log("setCurrentLocation", location);
    currentLocation = location;

    queryClient.invalidateQueries('currentLocation');
    queryClient.invalidateQueries('process');

    await _updateLocationUsers(location);
    await _updateLocationRoles(location);
    await _updateLocationTasks(location);
}

export const useCurrentLocation = () => {
    return useQuery('currentLocation', async () => { return currentLocation });
}


export const useSaveLocation = () => {
    return useMutation(async (location: Location) => {
        await location.save();

        if (location === currentLocation) {
            console.log("Changing current location...");
            queryClient.invalidateQueries('currentLocation');
        }
    })
}

