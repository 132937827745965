import {IOLocationShift} from "./schedule";
import {IODateNumber, IOLocationEvent, IOWeeklyHourlySchedule} from "./time";
import {IOTaskSchedule} from "./processSchedule";
import {IOProcessId} from "./process";
import * as Parse from "parse";

interface IOLocationSettings {
    maxWorkHours?: number;
    scheduleStartDay?: 0|1|2|3|4|5|6; // 0-6 which day to start week on (0-Sunday, 1-Monday)
}

export interface IOLocation extends Parse.Attributes{
    id: string;
    name: string;

    pictureURL?: string;

    shifts?: IOLocationShift[];

    hours?: IOWeeklyHourlySchedule;
    events?: IOLocationEvent[];

    // Task Schedule
    schedule?: Record<IOProcessId, IOTaskSchedule>;
    scheduleBuiltForDay?: IODateNumber;

    // The published schedule
    publishedSchedule?: IODateNumber;

    settings?: IOLocationSettings;
}

export class Location extends Parse.Object<IOLocation> {

    constructor(attr?: Partial<IOLocation>) {
        super('Location', attr as IOLocation);
    }

    get key(): string { return this.id }

    get name(): string { return this.get("name") }

    set name(name: string) { this.set("name", name); }

    get pictureURL(): string | undefined { return this.get("pictureURL") }

    set pictureURL(url: string | undefined) { this.set("pictureURL", url) }

    get shifts(): IOLocationShift[] { return this.get("shifts") || [] }

    set shifts(shifts: IOLocationShift[]) { this.set("shifts", shifts) }

    get hours(): IOWeeklyHourlySchedule { return this.get("hours") || []; }

    set hours(hours: IOWeeklyHourlySchedule) { this.set("hours", hours )}

    get events(): IOLocationEvent[] { return this.get("events") || [] }

    set events(events: IOLocationEvent[]) { this.set("events", events); }

    get schedule(): Record<IOProcessId, IOTaskSchedule> { return this.get("schedule") || {} }

    set schedule(schedule: Record<IOProcessId, IOTaskSchedule>) { this.set("schedule", schedule )}

    get publishedSchedule(): IODateNumber | undefined { return this.get("publishedSchedule") }

    set publishedSchedule(url: IODateNumber | undefined) { this.set("publishedSchedule", url) }

    get settings(): IOLocationSettings { return this.get("settings") || {} }

    set settings(settings: IOLocationSettings) { this.set("settings", settings); }
}

// @ts-ignore
Parse.Object.registerSubclass('Location', Location);

export interface IOLocationUser {
    id: string;
    name: string;
    email: string;

    roles?: string[]; // ids of roles
    admin?: boolean; // If user is admin. This can be used by UI but backend checks for admin role <locationId>_admin

    accountId?: string;
    secret?: { hourPay: number }
}

export class LocationUser extends Parse.Object<IOLocationUser & { location: Location, account: Parse.User }> {

    constructor(attr?: Partial<IOLocationUser>) {
        super('LocationUser', attr as any);
    }

    validate(attrs: Parse.Attributes, options?: Parse.SuccessFailureOptions): Parse.Error | false {
        return super.validate(attrs, options);
    }

    get key(): string { return this.id }

    get name(): string { return this.get("name") }

    set name(name: string) { this.set("name", name); }

    get email(): string { return this.get("email") }

    set email(email: string) { this.set("email", email); }

    get canMessage(): boolean {
        const email = this.get("email");
        return email?.length > 5;
    }

    get roles(): string[] { return this.get("roles") || [] }

    set roles(roles: string[]) { this.set("roles", roles); }

    get admin(): boolean { return this.get("admin") || false }

    set admin(admin: boolean) { this.set("admin", admin); }

    get secret(): { hourPay: number } { return this.get("secret") || {} as {hourPay: number} }

    set secret(secret: { hourPay: number }) { this.set("secret", secret)}
}

// @ts-ignore
Parse.Object.registerSubclass('LocationUser', LocationUser);


export const initials = (user: IOLocationUser | LocationUser) => {
    let initials = user.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
}


export interface IOLocationRole {
    id: string;
    name: string;
    color: string;
    description: string;
}

export class LocationRole extends Parse.Object<IOLocationRole & { location: Location }> {

    constructor(attr?: Partial<IOLocationRole>) {
        super('LocationRole', attr as any);
    }

    get key(): string { return this.id }

    get name(): string { return this.get("name") }

    set name(name: string) { this.set("name", name); }

    get color(): string { return this.get("color") }

    set color(color: string) { this.set("color", color); }

    get description(): string { return this.get("description") }

    set description(description: string) { this.set("description", description); }
}

// @ts-ignore
Parse.Object.registerSubclass('LocationRole', LocationRole);
