// Days (in DB)
import {format, formatDuration} from "date-fns";

export type IODay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export enum IODays {
    Sun = 0,
    Mon = 1,
    Tue = 2,
    Wed = 3,
    Thu = 4,
    Fri = 5,
    Sat = 6
}

export const ALL_DAYS = [0,1,2,3,4,5,6];

// Number is number of minutes since midnight (used for day scheduling)
// Important since a day can go past midnight (can be bigger than 86400)
export type IOMinutesFromMidnight = number;

// Number is number of minutes
export type IOMinutes = number;

export type IODateNumber = number;

export const toIODateNumber = (date: Date): IODateNumber => {
    return date.getDate() + ((date.getMonth() + 1) * 100) + date.getFullYear() * 10000;
}

export const todayIODateNumber = () => {
    return toIODateNumber(new Date());
}

export const fromIODateNumber = (num: IODateNumber) => {
    const date = num % 100;
    const month = (Math.floor(num / 100) % 100) - 1;
    const year = Math.floor(num / 10000);
    return new Date(year, month, date);
}

export const dayFromNumber = (dayCode: number) => {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayCode];
}



export const toIOMinutesFromMidnight = (date: Date): IOMinutesFromMidnight => {
    return date.getHours() * 60 + date.getMinutes();
}

export const fromIOMinutesFromMidnight = (number: IOMinutesFromMidnight): Date => {
    const date = new Date();
    date.setHours(Math.floor(number/60), number % 60, 0, 0);
    return date;
}

export const describeDuration = (duration: number | [IOMinutesFromMidnight, IOMinutesFromMidnight]) => {
    const timeDuration = typeof duration === 'number' ? duration : duration[1] - duration[0];

    return formatDuration({ hours: Math.floor(timeDuration/60), minutes: timeDuration % 60 })
}

export const describeIODateNumberAsDay = (day: IODateNumber) => {
    return format(fromIODateNumber(day), "eeee");
}

export const describeIODateNumberAsDayOfMonth = (day: IODateNumber) => {
    return format(fromIODateNumber(day), "do");
}

export const describeIOMinutesFromMidnight = (time: IOMinutesFromMidnight) => {
    let hour = Math.floor(time / 60);
    if (hour >= 24) hour -= 24; // Past Midnight can be higher.. display as AM

    const min = time % 60;

    if (hour >= 12) {
        return "" + (hour === 12 ? 12 : hour - 12) + (min !== 0 ? (min < 10 ? ":0" + min : ":" + min) : "") + "PM";
    } else {
        return "" + hour + (min !== 0 ? (min < 10 ? ":0" + min : ":" + min) : "") + "AM";
    }

    // return (hour < 10 ? "0" + hour : "" + hour) + ":" + (min < 10 ? "0" + min : min);
};



export interface IOHourSchedule {
    start: IOMinutesFromMidnight;
    end: IOMinutesFromMidnight;
}

export type IOWeeklyHourlySchedule = (IOHourSchedule|undefined)[];

export interface IOLocationEvent {
    description: string;
    dateNumber: IODateNumber;
    hours: IOHourSchedule | undefined;
}
