import {getCurrentLocation} from "./location";
import {getCurrentUserAccount} from "./account";
import {getTodaysTasks} from "./task";
import {getCurrentLocationUser} from "./locationUser";

// @ts-ignore
window.data = () => {
    return {
        currentLocation: getCurrentLocation(),
        currentUser: getCurrentLocationUser(),
        currentAccount: getCurrentUserAccount(),
        tasks: getTodaysTasks()
    }
};
