import {useQuery} from "react-query";
import {queryClient} from "../../App";
import "../server";
import {Parse} from "../server";
import {Location} from "../model/location";
import {getCurrentLocation, setCurrentLocation} from "./location";

import "./index";

let currentUser = Parse.User.current();
let currentUserIsDev = false;
let allLocations: Location[];

export const getCurrentUserAccount = (): Parse.User | undefined => { return currentUser };

export const isDev = (): boolean => { return currentUserIsDev };

export const useCurrentUserAccount = () => {
    return useQuery('currentAccount', async () => { return currentUser });
}

export const useCurrentUserAccountLocations = () => {
    return useQuery('currentAccountLocations', async () => { return allLocations });
}

export const updateAccountInfo = () => {

    console.log("updateAccountInfo: updateLocations");

    // Load users accounts, set Current location..
    new Parse.Query("Location").find().then((locations: Location[]) => {
        console.log("export const updateAccountInfo = () => {\n Locations is ", locations);
        allLocations = locations;
        Parse.Object.pinAll(locations);

        queryClient.invalidateQueries('currentAccountLocations');

        if (allLocations?.length === 1) {
            setCurrentLocation(allLocations[0]);
        } else {
            const storedLocation = localStorage.getItem("location");
            if (storedLocation) {
                const selectedLocation = allLocations.find(loc => loc.id === storedLocation);
                if (selectedLocation) {
                    setCurrentLocation(selectedLocation);
                }
            }
            if (!getCurrentLocation() && allLocations.length > 0) {
                const selectedLocation = allLocations[0];
                localStorage.setItem("location", selectedLocation.id);
                setCurrentLocation(selectedLocation);
            }
        }
    });

    console.log("updateAccountInfo: Update Roles");

    // Load users accounts, set Current location..
    new Parse.Query(Parse.Role).equalTo("users", currentUser).find().then((roles: Parse.Role[]) => {
        console.log("updateAccountInfo: roles ", roles);
        for (const role of roles) {
            if ("admin" === role.getName()) {
                currentUserIsDev = true;
            }
        }
    });

}
if (currentUser) {
    updateAccountInfo();
}

export const login = async (user: string, password: string) => {
    currentUser = undefined;
    queryClient.invalidateQueries('currentAccount');

    currentUser = await Parse.User.logIn(user, password);
    queryClient.invalidateQueries('currentAccount');

    console.log("Checking to see if user has new locations added..");
    await Parse.Cloud.run("checkNewLocations");

    updateAccountInfo();

    return currentUser;
}

export const logout = async () => {
    currentUser = undefined;
    queryClient.invalidateQueries('currentAccount');
    await Parse.User.logOut();
}

// @ts-ignore
window.logout = logout;
