import {Location, LocationUser} from "../model/location";
import {LiveQuerySubscription} from "parse";
import {queryClient} from "../../App";
import {Parse} from "../server";
import {useMutation, useQuery} from "react-query";
import {getCurrentUserAccount} from "./account";
import {getCurrentLocation} from "./location";

let locationUsers: LocationUser[] = [];
let locationUserSubscription: LiveQuerySubscription | undefined;

export const _updateLocationUsers = async (location: Location | undefined) => {

    // Listen to users

    if (locationUserSubscription) {
        locationUserSubscription.unsubscribe();
        locationUserSubscription = undefined;
    }

    locationUsers = [];
    queryClient.invalidateQueries('locationUsers');
    queryClient.invalidateQueries('currentLocationUser');

    function userUpdate(user: LocationUser, remove: boolean) {
        const index = locationUsers.findIndex(lu => lu.id === user.id);

        if (remove) {
            if (index >= 0) {
                locationUsers.splice(index, 1);
            }
        } else {
            if (index === -1) {
                locationUsers.push(user);
            } else {
                locationUsers[index] = user;
            }
        }

        queryClient.invalidateQueries('locationUsers');
        queryClient.invalidateQueries('currentLocationUser');
        if (user) {
            queryClient.invalidateQueries(['locationUser', user.id]);
        }
    }

    if (!location) {
        return; // don't update queries..
    }

    /**
     * Keep users up to date at all times.
     */
    const locationUserQuery = new Parse.Query(LocationUser).equalTo("location", location);
    locationUsers = await locationUserQuery.find();
    queryClient.invalidateQueries('locationUsers');
    queryClient.invalidateQueries('currentLocationUser');

    locationUserSubscription = await locationUserQuery.subscribe()

    locationUserSubscription!.on("create", (object: Parse.Object) => {
        userUpdate(object as LocationUser, false);
    });
    locationUserSubscription!.on("enter", (object: Parse.Object) => {
        userUpdate(object as LocationUser, false);
    });
    locationUserSubscription!.on("update", (object: Parse.Object) => {
        userUpdate(object as LocationUser, false);
    });
    locationUserSubscription!.on("leave", (object: Parse.Object) => {
        userUpdate(object as LocationUser, true);
    });
    locationUserSubscription!.on("delete", (object: Parse.Object) => {
        userUpdate(object as LocationUser, true);
    });

}
export const getCurrentLocationUser = () => {
    const user = getCurrentUserAccount()!;

    if (user && locationUsers)
        return locationUsers.find(locationUser => locationUser.get("account")?.id === user.id);
}
export const useCurrentLocationUser = () => {
    return useQuery('currentLocationUser', async () => {
        return getCurrentLocationUser()
    });
}
export const useSaveLocationUser = () => {

    return useMutation(async (user: LocationUser) => {

        if (!user.get("location")) {
            user.set("location", getCurrentLocation()!);
        }

        await user.save();

        queryClient.invalidateQueries('locationUsers');
        queryClient.invalidateQueries(['locationUser', user.id]);
    })
}

export const useLocationUsers = () => {
    return useQuery('locationUsers', async () => {
        return locationUsers
    });
}

export const useLocationUser = (id?: string) => {

    return useQuery(['locationUser', id], async () => {
        return locationUsers.find(user => user.id === id)
    });
}
