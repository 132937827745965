import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ping} from "./app/server";

const renderReactDom = () => {
    ping();
    ReactDOM.render(
        <React.StrictMode>
                <App />
        </React.StrictMode>,
        document.getElementById('root')
    );
};

if (window.cordova) {
    document.addEventListener('deviceready', () => {
        renderReactDom();
    }, false);
} else {
    renderReactDom();
}

serviceWorker.register();
