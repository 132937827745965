import {Location, LocationRole} from "../model/location";
import {LiveQuerySubscription} from "parse";
import {Parse} from "../server";
import {queryClient} from "../../App";
import {useMutation, useQuery} from "react-query";
import {getCurrentLocation} from "./location";

let locationRoles: LocationRole[] = [];
let locationRoleSubscription: LiveQuerySubscription | undefined;

export const _updateLocationRoles = async (location: Location | undefined) => {

    if (locationRoleSubscription) {
        locationRoleSubscription.unsubscribe();
        locationRoleSubscription = undefined;
    }

    locationRoles = [];
    queryClient.invalidateQueries('locationRoles');

    if (!location) {
        return; // don't update queries..
    }

    function roleUpdate(role: LocationRole, remove: boolean) {
        const index = locationRoles.findIndex(lu => lu.id === role.id);

        if (remove) {
            if (index >= 0) {
                locationRoles.splice(index, 1);
            }
        } else {
            if (index === -1) {
                locationRoles.push(role);
            } else {
                locationRoles[index] = role;
            }
        }

        queryClient.invalidateQueries('locationRoles');
        if (role) {
            queryClient.invalidateQueries(['locationRole', role.id]);
        }
    }

    const locationRoleQuery = new Parse.Query(LocationRole).equalTo("location", location);
    locationRoles = await locationRoleQuery.find();
    queryClient.invalidateQueries('locationRoles');

    locationRoleSubscription = await locationRoleQuery.subscribe()

    locationRoleSubscription!.on("create", (object: Parse.Object) => {
        roleUpdate(object as LocationRole, false);
    });
    locationRoleSubscription!.on("enter", (object: Parse.Object) => {
        roleUpdate(object as LocationRole, false);
    });
    locationRoleSubscription!.on("update", (object: Parse.Object) => {
        roleUpdate(object as LocationRole, false);
    });
    locationRoleSubscription!.on("leave", (object: Parse.Object) => {
        roleUpdate(object as LocationRole, true);
    });
    locationRoleSubscription!.on("delete", (object: Parse.Object) => {
        roleUpdate(object as LocationRole, true);
    });
}

export const useSaveLocationRole = () => {

    return useMutation(async (role: LocationRole) => {

        if (!role.get("location")) {
            role.set("location", getCurrentLocation()!);
        }

        const savedRole = await role.save();

        queryClient.invalidateQueries('locationRoles');
    })
}

export const useLocationRoles = () => {
    return useQuery('locationRoles', async () => {
        return locationRoles
    });
}

export const useLocationRole = (id: string) => {
    return useQuery(['locationRole', id], async () => {
        return locationRoles.find(role => role.id === id)
    });
}
