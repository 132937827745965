import {Task} from "../model/process";
import {useMutation, useQuery} from "react-query";
import {getCurrentLocation} from "./location";
import {IODateNumber, toIODateNumber} from "../model/time";
import {queryClient} from "../../App";
import {Location} from "../model/location";
import {LiveQuerySubscription} from "parse";
import {Parse} from "../server";

let today : Date|IODateNumber;
let todayTasks: Task[] = [];
let todayTaskSubscription: LiveQuerySubscription | undefined;

export const getTodaysTasks = () => todayTasks;

export const _updateLocationTasks = async (currentLocation?: Location) => {

    if (todayTaskSubscription) {
        todayTaskSubscription.unsubscribe();
        todayTaskSubscription = undefined;
        todayTasks = [];
        queryClient.invalidateQueries(['tasks', today]);
    }

    if (!currentLocation) {
        return;
    }

    today = toIODateNumber(new Date());

    const todayTaskQuery = new Parse.Query(Task).equalTo("location", currentLocation).equalTo("day", today);
    todayTasks = await todayTaskQuery.find();
    queryClient.invalidateQueries(['tasks']);

    todayTaskSubscription = await todayTaskQuery.subscribe();
    console.log("Starting task subscription", todayTaskSubscription);

    if (todayTaskSubscription) {
        todayTaskSubscription.on("create", (object: Parse.Object) => {
            if (!todayTasks.includes(object as Task)) {
                todayTasks.push(object as Task);
                todayTasks = [...todayTasks];
                console.log("task create", object);
                queryClient.invalidateQueries(['tasks', today]);
                queryClient.invalidateQueries(['tasks', object.id]);
            }
        });
        todayTaskSubscription.on("enter", (object: Parse.Object) => {
            if (!todayTasks.includes(object as Task)) {
                todayTasks.push(object as Task);
                todayTasks = [...todayTasks];
                console.log("task enter", object);
                queryClient.invalidateQueries(['tasks', today]);
                queryClient.invalidateQueries(['tasks', object.id]);
            }
        });
        todayTaskSubscription.on("update", (object: Parse.Object) => {
            const index = todayTasks.findIndex(task => task.id === object.id);
            console.log("task update", object, index);
            if (index >= 0) {
                todayTasks[index] = object as Task;
            }
            todayTasks = [...todayTasks];
            queryClient.invalidateQueries(['tasks', today]);
            queryClient.invalidateQueries(['tasks', object.id]);
        });
        todayTaskSubscription.on("leave", (object: Parse.Object) => {
            const index = todayTasks.findIndex(task => task.id === object.id);
            console.log("task leave", object, index);
            if (index >= 0) {
                todayTasks.splice(index, 1);
            }
            todayTasks = [...todayTasks];
            queryClient.invalidateQueries(['tasks', today]);
            queryClient.invalidateQueries(['tasks', object.id]);
        });
        todayTaskSubscription.on("delete", (object: Parse.Object) => {
            const index = todayTasks.findIndex(task => task.id === object.id);
            if (index >= 0) {
                todayTasks.splice(index, 1);
            }
            todayTasks = [...todayTasks];
            queryClient.invalidateQueries(['tasks', today]);
            queryClient.invalidateQueries(['tasks', object.id]);
        });
    }


}

export const useTasksByDay = (day?: IODateNumber) => {
    return useQuery<Task[]>(['tasks', day], async () => {
        if (!day) return [];

        console.log("Refreshing tasks useTasksByDay ", day);

        if (day === today && todayTaskSubscription) return todayTasks;

        const currentLocation = getCurrentLocation();

        let query = new Parse.Query(Task).equalTo("location", currentLocation)
            .equalTo("day", day);
        return query.find();
    }, {
        refetchOnWindowFocus: false
    });
};

export const useTask = (id?: string) => {
    return useQuery<Task>(['tasks', id], async () => {
        if (!id) return undefined;

        return new Parse.Query(Task).get(id);
    }, {
        refetchOnWindowFocus: false
    });
};

export const useSaveTask = () => {

    return useMutation(async (task: Task) => {
        if (task.id)
            await queryClient.cancelQueries(['task', task.id]);

        if (!task.get("location")) {
            task.set("location", getCurrentLocation()!);
        }

        const savedTask = await task.save();

        // Update query directly for id
        queryClient.setQueryData(['tasks', task.id], task);

        // Update query for day
        const dayData: Task[] | undefined = queryClient.getQueryData(['tasks', savedTask.day]);
        if (dayData) {
            const index = dayData.findIndex(t => t.id === task.id);
            if (index >= 0) {
                dayData[index] = task;
            } else {
                dayData.push(task);
            }

            queryClient.setQueryData(['tasks', task.day], [...dayData]);
        }

        return savedTask;
    })
}

