export const Parse = require('parse');
Parse.initialize(
    "iBQ08lqc9k1evNPU54pHwxdnB0eQbTY7yCGXjAGH",
    "Pr2oo5IoDocwYvzGUM29VZ7AxuXqKtejVnhDMIz4"
);

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost' || location.hostname.startsWith('applocal') || location.hostname.startsWith("192.168")) {
    // eslint-disable-next-line no-restricted-globals
    Parse.serverURL = `http://localhost:1337/parse`;
} else {
    Parse.serverURL = 'https://parseapi.back4app.com/';
}

Parse.enableLocalDatastore();
// window.Parse = Parse;

// const ls = localStorage;
// Parse.setLocalDatastoreController({
//     fromPinWithName: (name: string) => ls.getItem(name),
//     pinWithName: (name: string, objects: any) => {
//         console.log("pinWithName", name, objects);
//         ls.setItem(name, JSON.stringify(objects))
//     },
//     unPinWithName: (name: string) => ls.remove(name),
//     getAllContents: () => {
//         let data: any = {};
//         for (let i = 0 ; i < ls.length ; i++) {
//             const key = ls.key(i);
//             if (key) {
//                 const value = ls.getItem(key);
//                 data[key] = value && value.includes('{') ? JSON.parse(value) : value;
//             }
//         }
//         return data;
//     },
//     clear: () => ls.clear()
// })

//
// console.log(">> current: ", Parse.User.current());

export const ping = () => {
    console.log("[Parse] Server initialized.. ", Parse);
}

